import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportFlowrateConsumptionWeekly() {
  const {
    waterQuality: {
      filterFlowrates: {
        weeklyConsumption: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  let dataOverview = [];
  const { data = { reportFlowrateConsumptionWeekly: [] }, loading } = useQuery(GET_OVERVIEW, {
    variables: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
    skip: skip({ yearStart, yearEnd, weekStart, weekEnd, dataPoint }),
  });

  const { unit, totalVolume } = data.reportFlowrateConsumptionWeekly;
  dataOverview.push({
    color: '#1890FF',
    text: 'flowrate_consumption',
    data: data.reportFlowrateConsumptionWeekly.data || [],
  });
  return { loading, unit, total: totalVolume, data: dataOverview || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportFlowrateConsumptionWeekly(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dataPoint: String!
  ) {
    reportFlowrateConsumptionWeekly: ReportFlowrateConsumptionWeekly(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dataPoint: $dataPoint
    ) {
      _id
      unit
      totalVolume
      data {
        date
        value
      }
    }
  }
`;
