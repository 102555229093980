import { SettingDataPoint } from 'utils';

let startState = {
  account: {
    email: '',
    password: '',
  },
  resetPassword: {
    email: '',
  },
  setPassword: {
    confirmPassword: '',
    newPassword: '',
  },
  profile: {
    changePassword: {
      newPassword: '',
      confirmPassword: '',
    },
  },
  environment: {
    maxandmin: {
      historicLog: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        block: null,
      },
      log: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        block: null,
        device: null,
      },
    },
    weather: {
      log: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      historicLog: {
        yearStart: null,
        yearEnd: null,
        dayStart: null,
        weekStart: null,
        weekEnd: null,
        dayEnd: null,
        dataPoint: null,
      },
      historicDailyLog: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
    },
  },
  electric: {
    power: {
      log: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      historicLog: {
        yearStart: null,
        yearEnd: null,
        dayStart: null,
        weekStart: null,
        weekEnd: null,
        dayEnd: null,
        dataPoint: null,
      },
      historicDailyLog: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      dailyConsumption: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        dataPoint: null,
      },
      weeklyConsumption: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        dataPoint: null,
      },
    },
  },
  waterQuality: {
    waterPreparantion: {
      historicLog: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        dataPoint: null,
      },
      log: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
      },
    },
    filterPressure: {
      register: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      graphicLog: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      graphicLogMultiple: {
        year: null,
        week: null,
        day: null,
        dataPoints: [],
      },
    },
    gravityFilters: {
      register: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
      graphicLog: {
        year: null,
        week: null,
        day: null,
        dataPoint: null,
      },
    },
    filterOrp: {
      register: {
        year: null,
        week: null,
        day: null,
      },
      graphicLog: {
        year: null,
        week: null,
        day: null,
      },
    },
    filterTankLevel: {
      register: {
        year: null,
        week: null,
        day: null,
      },
      graphicLog: {
        year: null,
        week: null,
        day: null,
      },
    },
    filterFlowrates: {
      register: {
        year: null,
        week: null,
        day: null,
      },
      graphicLog: {
        year: null,
        week: null,
        day: null,
      },
      dailyConsumption: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        dataPoint: null,
      },
      weeklyConsumption: {
        yearStart: null,
        yearEnd: null,
        weekStart: null,
        weekEnd: null,
        dataPoint: null,
      },
    },
  },

  configuration: {
    user: {
      name: null,
      nameUser: null,
      address: null,
      password: null,
      verified: null,
      active: true,
      accessMobile: [],
      accesWebs: null,
      roles: [],
    },
    dataPoints: {
      name: null,
      longitude: null,
      latitude: null,
      active: true,
      module: '',
      location: null,
      time: null,
      diameter: null,
      setting: SettingDataPoint['temperature_and_humidity'],
    },
    waterpumps: {
      code: null,
      url: null,
    },
    threshold: {
      name: 'max_and_min_temperature',
      min: 0,
      max: 0,
      color: null,
    },
    devices: {
      name: null,
      code: null,
      type: null,
      color: '',
      data_point: null,
      active: true,
    },
  },
};

const States = (state = startState, action) => {
  let type = action.type.split('-');

  if (type.length < 2) return state;

  state = Object.assign({}, state);
  let keys = action.key.split('.');
  let obj = state;

  while (keys.length > 1) {
    if (!obj[keys[0]]) obj[keys[0]] = {};
    obj = obj[keys.shift()];
  }

  obj[keys[0]] = action.value;
  obj[keys[0] + '-valid'] = action.valid;
  return state;
};

export default States;
