import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

export function useReportFlowrateConsumptionDaily() {
  const {
    waterQuality: {
      filterFlowrates: {
        dailyConsumption: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
      },
    },
  } = useSelector(({ state }) => state);
  const { data = { ReportFlowrateConsumptionDaily: {} }, loading } = useQuery(GET_OVERVIEW, {
    variables: { yearStart, yearEnd, weekStart, weekEnd, dataPoint },
    skip: skip({ yearStart, yearEnd, weekStart, weekEnd, dataPoint }),
  });

  let { totalVolume } = data.ReportFlowrateConsumptionDaily;
  return { loading, total: totalVolume, data: data.ReportFlowrateConsumptionDaily.weeks || [] };
}

function skip(variables) {
  let skip = true;
  let { yearStart, yearEnd, weekStart, weekEnd, dataPoint } = variables;
  skip = ![yearStart, yearEnd, weekStart, weekEnd, dataPoint].every((v) => v);
  return skip;
}

const GET_OVERVIEW = gql`
  query ReportFlowrateConsumptionDaily(
    $yearStart: Int!
    $yearEnd: Int!
    $weekStart: Int!
    $weekEnd: Int!
    $dataPoint: String!
  ) {
    ReportFlowrateConsumptionDaily(
      yearStart: $yearStart
      yearEnd: $yearEnd
      weekStart: $weekStart
      weekEnd: $weekEnd
      dataPoint: $dataPoint
    ) {
      totalVolume
      weeks {
        _id
        text
        color
        data {
          weekday
          value
        }
      }
    }
  }
`;
